<ng-template #approvePopup>
	<ul>
		<li class="update-delete-popover" (click)="onDelete($event)">
			<mat-icon class="download-icon">
				delete_forever
			</mat-icon>
			<a class="alignment">{{ 'gridActions.delete' | translate }}</a>
		</li>
		<li class="update-delete-popover" (click)="requestToJp()"
			*ngIf="checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)">
			<a class="alignment">{{ 'gridActions.requestJPTranslation' | translate }}</a>
		</li>
	</ul>
</ng-template>

<ng-template #deleteUpdatePopup>
	<ul>
		<li class="update-delete-popover" (click)="onUpdate($event)">
			<mat-icon class="download-icon">
				create
			</mat-icon>
			<a class="alignment">{{ 'gridActions.updateEntity' | translate }}</a>
		</li>
		<li class="update-delete-popover" (click)="onDelete($event)">
			<mat-icon class="download-icon">
				delete_forever
			</mat-icon>
			<a class="alignment">{{ 'gridActions.deleteEntity' | translate }}</a>
		</li>
	</ul>
</ng-template>

<ng-template #BulkUploadPopup>
	<ul>
		<li class="update-delete-popover" (click)="addToCart($event)" *ngIf="params.data.status != 'Tat_Not_Available'">
			<a class="alignment">{{ 'bulk.upload.addToCart' | translate }}</a>
		</li>
		<li class="update-delete-popover" (click)="onRemove($event)">
			<a class="alignment">{{ 'bulk.upload.remove' | translate }}</a>
		</li>
	</ul>
</ng-template>

<ng-template #thankYouPopup>
	<ul>
		<li class="popover-list" (click)="requestToJp()"
			*ngIf="checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)">
			<a>{{ 'gridActions.requestJPTranslation' | translate }}</a>
		</li>
	</ul>
</ng-template>

<!-- <ng-template #tipContent>
	<div class="custom-multi-tooltip">
		<div>
			<div><label>{{params.data.availableAlerts[0].monitoringName}}</label></div>
			<div *ngIf="params.data.availableAlerts[0].isSubscribed">(Subscribed)</div><br>
			<div *ngIf="!params.data.availableAlerts[0].isSubscribed">(Unsubscribed)</div><br>
			<div><label>About this service:</label></div>
			<div>{{params.data.availableAlerts[0].description}}</div>
		</div>
		<mat-icon class="tooltip-arrow-custom">arrow_drop_down</mat-icon>
	</div>
</ng-template> -->

<ng-template #orderHistoryPopup>
	<ul>
		<li class="popover-list" *ngIf="checkValid(params.data) && params.data.itemStatus != 'Cancelled'"
			(click)="requestToJp()">
			<a>{{ 'gridActions.requestJPTranslation' | translate }}</a>
		</li>
		<!-- <li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Archived') ? 'popover-list disable-option' : 'popover-list'" 
			(click)="downloadReport('html')">
			<a>{{ 'gridActions.collectHTML' | translate }}</a>
		</li> -->
		<span *ngIf="validJSONCopy(params.data)">
			<li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Cancelled' || params.data.archived ||  params.data.categoryId == 4 || params.data.categoryId == 5|| params.data.categoryId == 6|| params.data.categoryId == 7 ) ? 'popover-list disable-option' : 'popover-list'"
				(click)="downloadReport('json')">
				<a>{{ 'gridActions.collectJSON' | translate }}</a>
			</li>
		</span>
		<li [ngClass]="(params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Cancelled' || params.data.archived) ? 'popover-list disable-option' : 'popover-list'"
			(click)="downloadReport('downlaod')">
			<a>{{ 'gridActions.collectPDF' | translate }}</a>
		</li>
		<li class="popover-list" (click)="downloadReport('billing')">
			<a>{{ 'gridActions.billingStatement' | translate }}</a>
		</li>
	</ul>
</ng-template>

<img *ngIf="!isMobile && params.label != 'cart' && params.label != 'thank-you' && params.label != 'order-history'
			&& params.label != 'monitor-records' && params.label != 'previously-deleted' && params.label != 'bulk-upload' && params.label != 'monitor-alerts' && params.label != 'monitor-status' && params.label != 'monitor-alert-clientfileref'"
	class="img-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="approvePopup"
	placement="bottom-right">

<div style="display: flex;"
	*ngIf="isMobile && params.label != 'cart' && params.label != 'thank-you' && params.label != 'order-history'
		&& params.label != 'monitor-records' && params.label != 'previously-deleted' && params.label != 'bulk-upload' && params.label != 'monitor-alerts' && params.label != 'monitor-status' && params.label != 'search-result' && params.label != 'monitor-search-result' && params.label != 'monitor-alert-clientfileref'">
	<div class="img-dots">
		<img src="/assets/img/icon-dots.svg" container="body" (click)="openBottomSheet('approvePopup')"
			placement="bottom-right">
	</div>

	<div (click)="expand($event)">
		<mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>

<div *ngIf="isMobile && params.label === 'search-result'">
	<div (click)="expand($event)">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
<div *ngIf="isMobile && params.label === 'monitor-search-result'">
	<div (click)="expand($event)" class="m-t-2">
		<mat-icon class="expand-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="expand-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>
<div class="col-md-12 row" *ngIf="params.label === 'thank-you'">
	<div class="col-md-6">

		<img *ngIf="!isMobile && checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)"
			class="img-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="thankYouPopup"
			placement="bottom-right">

		<div style="display: flex;">
			<div class="img-dots"
				*ngIf="isMobile && checkValid(params.data) && (!params.data.parentCartSeqNo && !showTranslation)">
				<img src="/assets/img/icon-dots.svg" container="body" (click)="openBottomSheet('thankYouPopup')"
					placement="bottom-right">
			</div>

			<!-- <div style="margin-right: 12px;" (click)="expand($event)" *ngIf="isMobile">
				<mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
				<mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
			</div> -->
		</div>

		<img *ngIf="!checkValid(params.data) && (params.data.parentCartSeqNo && showTranslation)"
			style="pointer-events: none;" class="img-dots" src="/assets/img/disabled-icon-dots.svg">


		<img *ngIf="params.data.parentCartSeqNo && showTranslation" style="pointer-events: none;" class="img-dots"
			src="/assets/img/disabled-icon-dots.svg">
		<img *ngIf="params.data.parentCartSeqNo && !showTranslation" style="pointer-events: none;" class="img-dots"
			src="/assets/img/disabled-icon-dots.svg">
		<img *ngIf="!params.data.parentCartSeqNo && showTranslation" style="pointer-events: none;" class="img-dots"
			src="/assets/img/disabled-icon-dots.svg">

	</div>
</div>

<div *ngIf="params.label == 'bulk-upload'">
	<div *ngIf="params.data.status != 'Added_To_Cart'">
		<img class="img-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="BulkUploadPopup"
			placement="bottom-right">
	</div>
	<div *ngIf="params.data.status == 'Added_To_Cart'">
		{{ 'bulk.upload.addedToCart' | translate }}
	</div>
	<div *ngIf="params.data.status == 'Added_To_Cart'" class="link" (click)="onRemove($event)">
		{{ 'bulk.upload.remove' | translate }}
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'order-history'">
	<div style="margin-right: 12px;" class="m-r-0" *ngIf="!isMobile">
		<mat-icon class="download-icon"
			*ngIf="((params.data.itemStatus != 'Processing' && params.data.itemStatus != 'Cancelled') && !params.data.archived)"
			(click)="downloadReport('downlaod')">
			get_app
		</mat-icon>

		<mat-icon class="disabled-download-icon"
			*ngIf="params.data.itemStatus == 'Processing' || params.data.itemStatus == 'Cancelled' || params.data.archived">
			get_app
		</mat-icon>
	</div>
	<div *ngIf="!isMobile">
		<img class="icon-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="orderHistoryPopup"
			placement="bottom-right">
	</div>
	<div *ngIf="isMobile" style="display: flex;">
		<div class="img-dots">
			<img src="/assets/img/icon-dots.svg" container="body" (click)="openBottomSheet('orderHistoryPopup')"
				placement="bottom-right">
		</div>
		<div (click)="expand($event)">
			<mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
			<mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
		</div>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'monitor-records'">
	<div style="margin-right: 12px;">
		<mat-icon class="shopping-icon" svgIcon="shopping">
		</mat-icon>
	</div>
	<div>
		<img class="icon-dots" src="/assets/img/icon-dots.svg" container="body" [ngbPopover]="deleteUpdatePopup"
			placement="bottom-right">
	</div>
	<div style="margin-right: 12px;">
		<mat-icon class="download-icon">keyboard_arrow_down</mat-icon>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'previously-deleted'">
	<div style="margin-right: 12px;" *ngIf="!isMobile">
		<mat-icon class="download-icon" (click)="onRestore($event)">
			history
		</mat-icon>
	</div>
	<div style="margin-right: 12px;" (click)="onDelete($event)" *ngIf="!isMobile">
		<mat-icon class="download-icon">
			delete_forever
		</mat-icon>
	</div>
	<div style="margin-right: 12px;" (click)="expand($event)" *ngIf="isMobile">
		<mat-icon class="download-icon" *ngIf="!params.data.expanded">keyboard_arrow_down</mat-icon>
		<mat-icon class="download-icon" *ngIf="params.data.expanded">keyboard_arrow_up</mat-icon>
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'monitor-status'">
	<div>
		<mat-icon class="icon-warning" *ngIf="params.data.entityStatus == 'Non-live'">
			warning
		</mat-icon>
	</div>
	<div class="pt-5">
		{{ params.data.entityStatus }}
	</div>
</div>

<div style="display: flex;" *ngIf="params.label == 'monitor-alerts'">
	<div style="margin-right: 12px;">
		<span *ngFor="let alert of params.data.availableAlerts">
			<span style="float: left;" [ngbTooltip]="tipContent1" container="body">
				<!-- *ngIf="alert.monitoringType !='NBB'" -->
				<div>
					<mat-icon [ngClass]="alert.isSubscribed == '1' ? 'subscribe': 'not-subscribe'" container="body"
						placement="top">{{alert.icon}} </mat-icon>
				</div>
				<div [ngClass]="alert.isSubscribed == '1' ? 'subscribe-names': 'not-subscribe-names'">
					{{ alert.monitoringType }}
				</div>
			</span>
			<ng-template #tipContent1>
				<div class="custom-multi-tooltip"> <!--  *ngIf="alert.monitoringType !='NBB'" -->
					<div>
						<div><label>{{alert.monitoringName}}</label></div>
						<div *ngIf="alert.isSubscribed == '1'">({{'monitor.subscribed' | translate}})</div><br
							*ngIf="alert.isSubscribed == '1'">
						<div *ngIf="alert.isSubscribed != '1'">({{'monitor.unsubscribed' | translate}})</div><br
							*ngIf="alert.isSubscribed != '1'">
						<div><label>{{'monitor.about_service' | translate}}:</label></div>
						<div>{{alert.description}}</div>
					</div>
					<mat-icon class="tooltip-arrow-custom">arrow_drop_down</mat-icon>
				</div>
			</ng-template>
		</span>
	</div>
</div>
<div style="display: flex;" *ngIf="params.label == 'monitor-alert-clientfileref'">
	<div style="margin-right: 12px;">
		<div *ngFor="let alert of params.data.availableAlerts">
			<span *ngIf="(alert.isSubscribed ==1) && (alert.clientFileReference != null)">
				{{alert.monitoringType}} : {{alert.clientFileReference}}
			</span>
		</div>
	</div>
</div>