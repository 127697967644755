import { Component, OnInit, Output, EventEmitter, HostListener, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NotificationService } from "../monitoring/shared-module/toast/toast.service";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { LoaderService } from 'src/app/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from 'src/app/home/home.service';
import { DocumentViewComponent } from 'src/app/document-view/document-view.component';
import { GlobalMonitoringService } from './global-monitoring.service';
import { MonitoringService } from '../monitoring/monitoring.service';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { ConfimDialogComponent } from '../confirm-dialog-box/confirm-dialog.component';
import * as moment from 'moment';
import { GridApi, GridOptions } from 'ag-grid-community';
import { GridCellRendererComponent } from '../grid-cell-renderer/grid-cell-renderer.component';
import { CustomToolTipComponent } from '../custom-tooltip/custom-tooltip.component';
import { ExpandGlobalGridCellRendererComponent } from './expand-global-grid-cell-renderer/expand-global-grid-cell-renderer.component';
import { GridLoaderComponent } from '../monitoring/shared-module/loader/loader.component';
import { MyNoRowsComponent } from '../monitoring/shared-module/search-result/no-overlay.component';
import { ErrorDialogComponent } from '../error-dialog-box/error-dialog.component';
import { GetDashboardDetails } from './global-monitoring';
import { GlobalDetailCellRenderer } from './global-master-data-grids/cell-renderer.component';
import { GlobalAlertTriggerCellRendererComponent } from './alert-trigger-cell-renderer/cell-renderer.component';
import { HttpClient } from '@angular/common/http';
import { GlobalFilterPopUpComponent } from './filter-popup/global-filter-popup.component';

@Component({
  selector: 'app-global-monitoring',
  templateUrl: './global-monitoring.component.html',
  styleUrls: ['./global-monitoring.component.scss']
})
export class GlobalMonitoringComponent implements OnInit {

  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }
  @Output() tabsEvent = new EventEmitter();
  changeLanEvent: Subject<void> = new Subject<void>();

  showToast: boolean = true;
  tooltipdata: any;

  title: any = "Global Monitoring";
  summuryData: any;
  addedRecord: any;
  selectedFilter: any = 'ALL';

  options: any;
  option: any = "ALL";
  expandButtonValue: any = "Expand all rows";
  buttonExpandValue: any = false;

  pagesCount: number = 1;
  defaultRowsCount: number = 1000;
  pageNumber: number = 1;
  entityNameFlter: any = '';
  totalRecords: any;
  $langEventSubscription: Subscription;
  $getDataSubscription: Subscription;

  @Input() buttonValue: any;
  @Input() buttonExpand: any;


  @Output() filterEvent = new EventEmitter();
  @ViewChild(DaterangepickerDirective) picker: DaterangepickerDirective;
  @ViewChild(DaterangepickerDirective) picker1: DaterangepickerDirective;
  confirmDialogRef: MatDialogRef<ConfimDialogComponent>;
  columnDefs: any = [];
  selected = [];
  gridOptions: any;
  gridColumnApi: any;
  startDate: any = undefined;
  endDate: any = undefined;
  format: any = "DD MMM YYYY";
  alwaysShowCalendars: boolean;
  loadingOverlayComponent: any;
  currentDate: any = moment().format('DD/MM/YYYY');
  loadingOverlayComponentParams: any;
  loadMessage: string = "Loading 1000 Records..."
  locale: any = {
    format: "DD MMM YYYY"
  };
  // startMinDate: any = moment(new Date()).subtract(89, 'days');
  minDate: any;
  maxDate: any = moment(new Date().setDate(new Date().getDate()));
  ranges: any = {
    'Today': [moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(7, 'days')],
    'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(30, 'days')],
    'Last 90 Days': [moment().subtract(89, 'days'), moment().subtract(89, 'days')]
  }
  invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];
  searchByEntityName: any;
  icons: any;
  tooltipShowDelay: any;
  blob: any;
  mySubscription: Subscription
  private paginationPageSize = 5;
  private totalPages = 0;
  api: any;
  initialFlag: boolean = false;
  domLayout: any = "autoHeight";
  alertList: any = [];
  alertListCopy: any = [];
  detailCellRenderer: any;
  detailRowHeight: number;
  minimize: any = true;
  loadNew: boolean = false;
  filterValue: any;
  avalilableResults: number = 0;
  showing: any;

  from: boolean = false;
  to: boolean = false;
  filter: boolean = false;
  showTip: boolean = true;
  allExpand: boolean;
  gridId: String;
  expandCollapseButtonValue: String = "Expand all rows"
  isglobal: boolean = false;

  get PaginationPageSize(): number {
    return this.paginationPageSize;
  }

  get gridAPI(): GridApi {
    return this.gridOptions.api;
  }

  get TotalPages(): number {
    return this.totalPages;
  }

  dateComparator(date11: any, date22: any) {
    var date1: any = moment(date11).format('DD/MM/YYYY');
    var date2: any = moment(date22).format('DD/MM/YYYY');
    var result1: any;
    var result2: any;
    if (date1 === undefined || date1 === null || date1.length !== 10) {
      result1 = null;
    } else {
      var yearNumber1: any = date1.substring(6, 10);
      var monthNumber1: any = date1.substring(3, 5);
      var dayNumber1: any = date1.substring(0, 2);
      result1 = yearNumber1 * 10000 + monthNumber1 * 100 + dayNumber1;
    }

    if (date2 === undefined || date2 === null || date2.length !== 10) {
      result2 = null;
    } else {
      var yearNumber2: any = date2.substring(6, 10);
      var monthNumber2: any = date2.substring(3, 5);
      var dayNumber2: any = date2.substring(0, 2);
      result2 = yearNumber2 * 10000 + monthNumber2 * 100 + dayNumber2;
    }
    if (result1 === null && result2 === null) {
      return 0;
    }
    if (result1 === null) {
      return -1;
    }
    if (result2 === null) {
      return 1;
    }
    return result1 - result2;
  }

  monthToComparableNumber(date: any) {
    if (date === undefined || date === null || date.length !== 10) {
      return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
  }


  constructor(
    public _notificationservice: NotificationService,
    public router: ActivatedRoute,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private globalService: GlobalMonitoringService,
    private service: MonitoringService,
    private homeService: HomeService,
    public loaderService: LoaderService,
    public translateService: TranslateService,
    public http: HttpClient
  ) {
    if (window.screen.width > 1000) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    if (localStorage.getItem('upgrade_plan_show') == null) {
      localStorage.setItem('upgrade_plan_show', "true");
    }
    this.getColDefs();
    localStorage.removeItem("dateFrom");
    localStorage.removeItem("dateTo");
    localStorage.removeItem("entityId");
    this.gridOptions = <GridOptions>{
      onGridReady: () => {
        setTimeout(() => {
          this.gridOptions.api?.sizeColumnsToFit();
        }, 1000);
        this.totalPages = this.gridOptions.api?.paginationGetTotalPages();
      },
      onGridSizeChanged: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
      noRowsOverlayComponent: 'myNoRowsComponent',
      noRowsOverlayComponentParams: {
        "component": "previously-deleted"
      },

      rowSelection: 'multiple',
      pagination: true,
      paginationPageSize: 10,
      animateRows: true,
      suppressCellSelection: true,
      enableCellTextSelection: true,
      suppressContextMenu: true,
      defaultColDef: {
        flex: 1,
        wrapText: true,
        resizable: true,
        autoHeight: true,
        unSortIcon: true,
        sortable: true,
        suppressMenu: true,
        lockPosition: true,
        tooltipComponent: 'customTooltip'
      }
    };

    this.detailCellRenderer = 'myDetailCellRenderer';
    this.detailRowHeight = 200;
    this.gridOptions.frameworkComponents = {
      "cellRenderer": GridCellRendererComponent,
      "customTooltip": CustomToolTipComponent,
      "expandCellRenderer": ExpandGlobalGridCellRendererComponent,
      "AlertTriggerCellRendererComponent": GlobalAlertTriggerCellRendererComponent,
      myDetailCellRenderer: GlobalDetailCellRenderer,
      customLoadingOverlay: GridLoaderComponent,
      myNoRowsComponent: MyNoRowsComponent
    };


    this.isglobal = this.isglobal;
    this.loadingOverlayComponent = 'customLoadingOverlay';
    this.loadingOverlayComponentParams = {
      loadingMessage: this.loadMessage
    };
    this.tooltipShowDelay = 0;
    this.icons = {
      sortUnSort: '<img src="../../assets/img/sort.svg">',
      sortAscending: '<img src="../../assets/img/sort-asc.svg">',
      sortDescending: '<img src="../../assets/img/sort-desc.svg">'
    };

    iconRegistry
      .addSvgIcon('payment', sanitizer.bypassSecurityTrustResourceUrl('assets/img/payment.svg'))
      .addSvgIcon('percentage', sanitizer.bypassSecurityTrustResourceUrl('assets/img/percentage.svg'));
    this.globalService.setSid(localStorage.getItem('sessionID'))
    this.alwaysShowCalendars = true;
  }

  ngOnInit(): void {
    this.$langEventSubscription = this.changeLanEvent.subscribe(() => this.changeLanguage());
  }

  ngAfterViewInit(): void {
    this.gridAPI.resetRowHeights();
  }

  changeLanguage() {
    this.getColDefs();
    this.gridOptions.api.refreshHeader({ forrce: true });
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    }, 100);
  }

  loadRecords(defaultRowsCount: any, pageNumber: any) {
    let data1: any = [];
    this.loaderService.isLoading.next(true);
    this.$getDataSubscription = this.globalService.getAllDashboardRecords(defaultRowsCount, pageNumber, this.filterValue).subscribe((data: GetDashboardDetails) => {
      if (data.success) {
        this.loaderService.isLoading.next(false);
        data1 = data.data.records;
        this.alertList = data1.filter((record: any) => !record.isArchive);
        this.totalRecords = this.alertList.length;
      } else {

        this.loaderService.isLoading.next(false);
        this.alertList = [];
        if (this.alertList.length > 0) {
          this.gridId = "alartsGrid"
        } else {
          this.gridId = "alartsGridMobile"
        }
        this.api.hideOverlay();
        this.openTryAgainPopup();
      }
    },
      (err: any) => {
        this.alertList = [];
        if (this.alertList.length > 0) {
          this.gridId = "alartsGrid"
        } else {
          this.gridId = "alartsGridMobile"
        }
        this.api.hideOverlay();
        this.openTryAgainPopup();
      }
    );
  }


  changeLanguageGrid(ev: any) {
    this.changeLanEvent.next();
  }



  setDefault() {
    this.selectedFilter = "ALL";
  }

  ngOnDestroy(): void {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
    if (this.$getDataSubscription) {
      this.$getDataSubscription.unsubscribe();
    }
  }

  userManualReport() {
    this.loaderService.isLoading.next(true);
    let orderSource = localStorage.getItem('orderSource');
    let lang = localStorage.getItem('lang');
    this.service.userManualReport().subscribe(res => {
      this.dialog.open(DocumentViewComponent, {
        width: '80vw',
        data: {
          response: res,
          format: 'PDF'
        }
      });
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  }

  description() {

  }

  hovered(alert: any) {
    this.tooltipdata = alert;
  }

  getColDefs() {
    this.translateService.get(['monitor.select', 'global_monitor.entity', 'monitor.clientFileRef', 'global_monitor.country',
      'monitor.alertType', 'monitor.alertTriggers', 'global_monitor.date',
      'monitor.action']).subscribe(value => {
        this.columnDefs = [
          {
            field: 'itemId',
            headerName: value['global_monitor.entity'],
            width: 220,
            minWidth: 220,
            cellRenderer: function (param: any) {
              var row = "<p style='line-height:50px;'></p>" + "Order Item ID: " + param.data.itemId + '<br/>';
              if (param.data.entityName)
                row = row + param.data.entityName + "</br> <p style='line-height:50px;'></p>";
              return row;
            },
            cellStyle: { 'overflow': 'visible', 'justify-content': 'left', 'font-size': '14px' }
          },
          {
            field: 'fileReference',
            headerName: value['monitor.clientFileRef'],
            tooltipField: 'clientFileRef',
            tooltipComponentParams: { type: 'fileRef' },
            width: 110,
            minWidth: 110,
            hide: this.isMobile,
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
          },
          {
            field: 'countryName',
            headerName: value['global_monitor.country'],
            tooltipField: 'country',
            tooltipComponentParams: { type: 'country' },
            width: 110,
            minWidth: 110,
            hide: this.isMobile,
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
          },
          {
            field: 'alertType',
            headerName: value['monitor.alertType'],
            width: 150,
            minWidth: 150,
            hide: this.isMobile,
            cellRenderer: function (param: any) {
              return param.data.alertType;
            },
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' },
            suppressMenu: false,
          },
          {
            field: 'AlertList',
            headerName: value['monitor.alertTriggers'],
            width: 230,
            minWidth: 230,
            hide: this.isMobile,
            suppressMenu: false,
            cellRenderer: "AlertTriggerCellRendererComponent",
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
          },
          {
            field: 'orderCompletionDate',
            headerName: value['global_monitor.date'],
            width: 115,
            minWidth: 115,
            hide: this.isMobile,
            suppressMenu: false,
            valueFormatter: (params: any) => {
              if (params.value != null && params.value != '' && params.value != undefined) {
                return moment(params.value).format('DD MMM YYYY');
              } else {
                return null;
              }
            },
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '13px 18px' }
          },
          {
            width: this.isMobile ? 60 : 120,
            maxWidth: this.isMobile ? 60 : 130,
            sortable: false,
            headerName: this.isMobile ? " " : value['monitor.action'],
            cellRenderer: "expandCellRenderer",
            cellRendererParams: {
              onClick: this.onActionClick.bind(this),
              label: 'monitor-records'
            },
            cellStyle: { 'overflow': 'visible', 'text-align': 'left', 'padding': '6px 18px', 'justify-content': 'center', 'font-size': '14px' }
          },
        ]
      });
  }

  dateGetter(params: any) {
    return moment(params.data.AlertDate).format('DD MMM YYYY');
  }

  onActionClick(ev: any) {
    if (ev.label === 'expand-data') {
      ev.data.expanded = !ev.node.expanded;
      ev.node.setExpanded(!ev.node.expanded);
      this.gridAPI.refreshCells(ev);
    }

    if (ev.label === 'delete') {
      this.gridAPI.redrawRows();
    }

    if (ev.label === 'expand') {
      localStorage.setItem('itemId', ev.data.itemId);
      window.open('#' + 'global-monitor/alert', "_blank");
      this.gridAPI.redrawRows();
    }

    if (ev.label === 'update') {
      this.gridAPI.redrawRows();
    }

    if (ev.actionEvent === 'download' || ev.actionEvent === 'json') {
      this.genrateReport(ev, 'JSON');
    }

    if (ev.actionEvent === 'pdf') {
      this.downloadReport(ev, 'PDF');
    }

    if (ev.actionEvent === 'delta_pdf') {
      this.downloadDeltaReport(ev);
    }

    if (ev.actionEvent === 'view') {
      this.viewReport(ev, 'JSON');
    }
  }

  ExpandAll(data: any) {
    if (!data) {
      this.gridOptions.api.forEachNode((node: any) => {
        node.data.expanded = true;
        node.expanded = true;
      });
      this.allExpand = true;
      this.expandCollapseButtonValue = "Collapse all rows"
    } else {
      this.gridOptions.api.forEachNode((node: any) => {
        node.data.expanded = false;
        node.expanded = false;
      });
      this.allExpand = false;
      this.expandCollapseButtonValue = "Expand all rows"
    }
    this.gridOptions.api.onGroupExpandedOrCollapsed();
  }

  onGridReady(params: any) {
    this.gridColumnApi = params.columnApi;
    this.api = params.api;
    this.loadRecords(this.defaultRowsCount, this.pageNumber);
  }

  onFilterChanged(event: any) {
    if (this.gridOptions.api.getDisplayedRowCount() == 0) {
      this.gridAPI.showNoRowsOverlay();
    } else {
      this.gridAPI.hideOverlay();
    }
    this.gridOptions.api.deselectAll();
  }

  loadNextRecords() {
    this.loadNew = true;
    this.loadRecords(this.defaultRowsCount, this.pageNumber);
  }

  loadPreviousRecords() {
  }

  hideTip() {
    this.showTip = false;
  }

  startDateUpdated() {
    if (this.startDate?.startDate && !this.from && !this.to) {
      this.minDate = moment(this.startDate.startDate);
      if (this.startDate?.endDate && moment(this.startDate.endDate).format('L') == moment().subtract(1, 'days').format('L')) {
        this.endDate = {
          "startDate": this.startDate.endDate,
          "endDate": this.startDate.endDate
        };
        this.startDate = {
          startDate: this.startDate.startDate,
          endDate: this.startDate.endDate
        };
      } else {
        this.endDate = {
          "startDate": new Date(),
          "endDate": new Date()
        };
        this.startDate = {
          startDate: this.startDate.startDate,
          endDate: new Date().setDate(new Date().getDate() - 1)
        };
      }
      this.from = true;
      this.to = false;
    } else if (!this.from && !this.to) {
      this.startDate = undefined;
      this.endDate = undefined;
    } else if (this.startDate?.startDate) {
      this.minDate = moment(this.startDate.startDate);
      if (this.startDate?.endDate && moment(this.startDate.endDate).format('L') == moment().subtract(1, 'days').format('L')) {
        this.endDate = {
          "startDate": this.startDate.endDate,
          "endDate": this.startDate.endDate
        };
        this.startDate = {
          startDate: this.startDate.startDate,
          endDate: this.startDate.endDate
        };
      } else {
        this.startDate = {
          startDate: this.startDate.startDate,
          endDate: new Date().setDate(new Date().getDate() - 1)
        };
        this.endDate = {
          startDate: new Date(),
          endDate: new Date().setDate(new Date().getDate() - 1)
        };
      }
    }
  }

  endDateUpdated() {
    if (this.endDate?.startDate && this.from) {
      this.startDate = {
        startDate: this.startDate.startDate,
        endDate: this.endDate?.startDate
      }
      if (this.from) {
        this.to = true;
      }
    } else {
      this.startDate = undefined;
      this.endDate = undefined;
    }
  }

  open(event: any) {
    this.picker.open();
  }

  applyFilter() {
    this.pageNumber = 1;
    this.filterValue = {
      "entityNameFilter": this.searchByEntityName ? this.searchByEntityName : "",
      "alertDateFromFilter": this.startDate?.startDate ? moment(this.startDate.startDate).format('MM-DD-YYYY') : "",
      "alertDateToFilter": this.endDate?.startDate ? moment(this.endDate.startDate).format('MM-DD-YYYY') : ""
    }
    this.loadNew = false;
    this.filter = true;
    this.filterEvent.emit();
    this.loadRecords(this.defaultRowsCount, this.pageNumber);
  }

  removeFilter() {
    this.pageNumber = 1;
    this.filterValue = null;
    this.loadNew = false;

    this.filter = false;
    this.searchByEntityName = "";

    this.selected = [];
    this.startDate = null;
    this.endDate = null;
    this.filterEvent.emit();
    this.loadRecords(this.defaultRowsCount, this.pageNumber);
    var obj: any = {
      "MonitoringType": [],
      "AlertClassification": []
    };
  }



  openFilterPopup() {
    let dialogRef = this.dialog.open(GlobalFilterPopUpComponent, {
      disableClose: true,
      width: this.isMobile ? '91vw' : '37vw',
      height: '80%',
      panelClass: 'search-result-pop-up',
      data: {
        label: 'monitor-alert-filter-popup'
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res != undefined) {
        this.applyPopoupFilter(res.data);
      }
    })
  }

  applyPopoupFilter(data: any) {
    this.pageNumber = 1;
    this.filterValue = data;
    this.loadNew = false;
    this.filter = true;
    this.filterEvent.emit();
    this.loadRecords(this.defaultRowsCount, this.pageNumber);
  }

  openTryAgainPopup() {
    this.translateService.get(['errorMsg.pleaseTryAgain']).subscribe(value => {
      let dialogRef = this.dialog.open(ErrorDialogComponent, {
        disableClose: true,
        width: this.isMobile ? '91vw' : '37vw',
        data: {
          errorMessage: value['errorMsg.pleaseTryAgain']
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          this.loadRecords(this.defaultRowsCount, this.pageNumber);
        }
      });
    })
  }

  genrateReport(ev: any, format: any) {
    this.loaderService.isLoading.next(true);
    var formData: any = new FormData();
    formData.append("itemId", ev.data.itemId);
    formData.append("reportFormat", format);
    formData.append("deltaReportFormat", ev.data.reportType);

    this.globalService.generateReport(formData).subscribe(async (res) => {
      if (format == 'JSON') {
        const a = document.createElement('a');
        a.setAttribute('download', ev.data.itemId + '.txt');
        a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((await res.text())));
        a.click();
      } else {
        this.dialog.open(DocumentViewComponent, {
          width: '80vw',
          data: {
            response: res,
            format: format
          }
        });
      }
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  }

  viewReport(ev: any, format: any) {
    this.loaderService.isLoading.next(true);
    var formData: any = new FormData();
    formData.append("itemId", ev.data.itemId);
    formData.append("reportFormat", format);

    this.globalService.viewReport(formData).subscribe(async (res) => {
      if (format == 'JSON') {
        const a = document.createElement('a');
        a.setAttribute('download', ev.data.itemId + '.txt');
        a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((await res.text())));
        a.click();
      } else {
        this.dialog.open(DocumentViewComponent, {
          width: '80vw',
          data: {
            response: res,
            format: format
          }
        });
      }
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  }


  downloadReport(ev: any, format: any) {
    this.loaderService.isLoading.next(true);
    var formData: any = new FormData();
    formData.append("itemId", ev.data.itemId);
    formData.append("reportFormat", format);
    formData.append("deltaReportFormat", ev.data.reportType);

    this.globalService.generateReport(formData).subscribe(async (res) => {
      if (format == 'JSON') {
        const a = document.createElement('a');
        a.setAttribute('download', ev.data.itemId + '.txt');
        a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((await res.text())));
        a.click();
      } else {
        this.dialog.open(DocumentViewComponent, {
          width: '80vw',
          data: {
            response: res,
            format: format
          }
        });
      }
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  }

  downloadDeltaReport(ev: any) {
    let format = 'PDF';
    this.loaderService.isLoading.next(true);
    var formData: any = new FormData();
    formData.append("itemId", ev.data.itemId);
    formData.append("reportFormat", format);
    this.globalService.generateDeltaReport(formData).subscribe(async (res: any) => {
      if (format == 'JSON') {
        const a = document.createElement('a');
        a.setAttribute('download', ev.data.itemId + '.txt');
        a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent((await res.text())));
        a.click();
      } else {
        this.dialog.open(DocumentViewComponent, {
          width: '80vw',
          data: {
            response: res,
            format: format
          }
        });
      }
      this.loaderService.isLoading.next(false);
    }, err => {
      this.loaderService.isLoading.next(false);
    });
  }

}
